export function capitalize(string) {
    if (typeof string !== "string") return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function startCase(string) {
    if (typeof string !== "string") return "";
    return string
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
        .replace(/[_-]+/g, " ") // Replace underscores and dashes with spaces
        .replace(/\s+/g, " ") // Normalize multiple spaces
        .trim() // Trim spaces from ends
        .split(" ") // Split into words
        .map((word) => capitalize(word)) // Capitalize each word
        .join(" "); // Join with space
}

export function isEqual(value, other) {
    if (value === other) return true; // Handle primitives and identical references

    if (value && typeof value === "object" && other && typeof other === "object") {
        if (Object.keys(value).length !== Object.keys(other).length) return false;

        for (const key in value) {
            if (!isEqual(value[key], other[key])) return false;
        }
        return true;
    }

    return false; // Values are not equal
}

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
