import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { cloneDeep } from "lodash";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import { saveUserSettings } from "../api/UserAPI";
import { updateSettings } from "../../reducers/settings/settingsSlice";

import { Dialog } from "@progress/kendo-react-dialogs";

import AccountSettingsRouter from "./AccountSettingsRouter";
import DotLottie from "./DotLottie";

import "../DemoPreview/GridSettings/GridSettings.css";

const AccountSettings = ({ toggle }) => {
    const dispatch = useDispatch();
    const { userId } = useSessionContext();
    const settings = useSelector((state) => state?.settings?.general || {});

    const [loading, setLoading] = useState(false);
    const [changeMade, setChangeMade] = useState(false);
    const [localSettings, setLocalSettings] = useState({});

    useEffect(() => {
        // make one copy of the redux store settings on mount
        setLocalSettings(structuredClone(settings));
    }, []); //eslint-disable-line

    const updateStoreSettings = () => {
        if (changeMade) {
            dispatch(updateSettings({ key: "general", newSettings: localSettings }));
            persistSettings();
        }
        setTimeout(() => {
            closeDialog();
        }, [250]);
    };

    const persistSettings = async () => {
        try {
            await saveUserSettings({ ...localSettings, userId });
        } catch (error) {
            console.log(error);
        }
    };

    const onClose = () => {
        if (!changeMade) {
            closeDialog();
            return;
        }
        if (changeMade) {
            setLoading(true);
            updateStoreSettings();
        }
    };

    const closeDialog = () => {
        setLoading(false);
        toggle();
    };

    return (
        <Dialog title={"Settings"} height={"90vh"} width={"90vw"} onClose={onClose} className="grid-settings-dialog">
            {!loading ? (
                <AccountSettingsRouter
                    settings={localSettings}
                    setSettings={setLocalSettings}
                    setChangeMade={setChangeMade}
                />
            ) : (
                <div className="d-flex justify-content-center align-items-center h-75 flex-column">
                    <DotLottie
                        src="https://asm-static-files.s3.amazonaws.com/syncing.lottie"
                        autoplay
                        style={{ height: 250, width: 250 }}
                        loop={false}
                        speed={0.33}
                    />
                    Syncing settings...
                </div>
            )}
        </Dialog>
    );
};

export default AccountSettings;
