import React, { useEffect, Suspense } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { useUser } from "../../providers/UserProvider";
import { useGetTaskCountQuery } from "../../services/tasks";
import { startCase } from "../../helpers/stringUtils";

import { Tabs, Tab } from "react-bootstrap";

import LoadingSuspense from "./LoadingSuspense";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMoneyBillAlt,
    faMoneyBillWave,
    faHandshake,
    faClipboard,
    faAddressBook,
    faRunning,
    faTasks,
    faBriefcase,
    // faHotdog,
} from "@fortawesome/free-solid-svg-icons";

import {
    faHandshake as farHandshake,
    faClipboard as farClipboard,
    faAddressBook as farAddressBook,
} from "@fortawesome/free-regular-svg-icons";

import PropertiesColor from "../../images/Status_Icons/Properties-24x24.svg";
import PropertiesGray from "../../images/Status_Icons/PropertiesGreyscale24x24.jpg";

import "./css/BSTabs.css";

const Dashboards = React.lazy(() => import("../../pages/DashboardsView/Dashboards"));
const AgreementRoutes = React.lazy(() => import("../../pages/AgreementsView/AgreementRoutes"));
const NewTasksView = React.lazy(() => import("../../pages/TasksView/TaskRoutes"));
const PaymentRoutes = React.lazy(() => import("../../pages/PaymentsView/PaymentRoutes"));
const PropertiesRoutes = React.lazy(() => import("../../pages/PropertiesView/PropertiesRoutes"));
const ContactsRoutes = React.lazy(() => import("../../pages/ContactsView/ContactsRoutes"));
const CompaniesRoutes = React.lazy(() => import("../../pages/CompaniesView/CompaniesRoutes"));
const ReportingRoutes = React.lazy(() => import("../../pages/ReportingView/ReportingRoutes"));
// const EventRoutes = React.lazy(() => import("../../pages/EventsView/EventRoutes"));

const DEFAULT_ACTIVE_TAB = "dashboards";

const RoutedTabs = () => {
    const state = useSelector((state) => state.views);
    // const taskLength = useSelector((state) => state.data.tasks.data.length ?? 0);
    const stickyHeaders = useSelector((state) => state.settings.general.stickyHeaders ?? true);

    const { modules = [] } = useUser();
    const { active_tab } = useParams();

    const history = useHistory();

    const { data } = useGetTaskCountQuery(
        {},
        {
            skip: modules.includes("Task") ? false : true,
            refetchOnMountOrArgChange: true,
        }
    );

    const numOfTasks = data?.count ?? 0;

    const toggle = (tab) => {
        if (active_tab !== tab) {
            if (document.location.origin.includes("demo")) {
                document.title = `Foliedge [DEMO] • ${startCase(tab)}`;
            } else {
                document.title = `Foliedge • ${startCase(tab)}`;
            }
        }
    };

    useEffect(() => {
        let tabs = [
            "dashboards",
            "tasks",
            "businesses",
            "contacts",
            "properties",
            "agreements",
            "payments",
            "reports",
            "events",
        ];
        if (!tabs.includes(active_tab)) {
            history.replace("/dashboards");
        }
    }, [active_tab, history]);

    return (
        <Suspense fallback={<LoadingSuspense delay={900} />}>
            <Tabs
                className={stickyHeaders ? `foliedge-tabs nav-justified tabs-sticky` : `foliedge-tabs nav-justified`}
                style={{ marginBottom: "0px" }}
                defaultActiveKey={DEFAULT_ACTIVE_TAB}
                activeKey={active_tab}
                id="foliedge-tabs"
                mountOnEnter={true}
                onSelect={toggle}
                transition={false}
            >
                <Tab
                    unmountOnExit={false}
                    tabClassName={
                        active_tab === "dashboards" || active_tab === undefined
                            ? "foliedge-tab"
                            : "foliedge-tab-inactive"
                    }
                    eventKey="dashboards"
                    title={
                        <Link to={"/dashboards"} className="foliedge-title-tab">
                            <span className="foliedge-title-tab">
                                {active_tab === "dashboards" || active_tab === undefined ? (
                                    <FontAwesomeIcon icon={faRunning} className="me-2" style={{ color: "#fdce2a" }} />
                                ) : (
                                    <FontAwesomeIcon icon={faRunning} className="me-2 fa-hover-hidden" />
                                )}
                                {active_tab !== "dashboards" || active_tab === undefined ? (
                                    <FontAwesomeIcon
                                        icon={faRunning}
                                        className="me-2 fa-hover-show"
                                        style={{ color: "#fdce2a" }}
                                    />
                                ) : null}
                                Dashboard
                            </span>
                        </Link>
                    }
                >
                    <Dashboards />
                </Tab>

                {/* {modules.includes("Task") ? (
                    <Tab
                        unmountOnExit={false}
                        tabClassName={active_tab === "events" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="events"
                        title={
                            <Link to={"/events"} className="foliedge-title-tab">
                                <span className="foliedge-title-tab">
                                    {active_tab === "events" ? (
                                        <FontAwesomeIcon
                                            icon={faHotdog}
                                            className="me-2"
                                            style={{ color: "#8a160a" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={faHotdog} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "events" ? (
                                        <FontAwesomeIcon
                                            icon={faHotdog}
                                            className="me-2 fa-hover-show"
                                            style={{ color: "#8a160a" }}
                                        />
                                    ) : null}
                                    Events
                                </span>
                            </Link>
                        }
                    >
                        <EventRoutes />
                    </Tab>
                ) : null} */}
                {modules.includes("Task") ? (
                    <Tab
                        unmountOnExit={false}
                        tabClassName={active_tab === "tasks" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="tasks"
                        title={
                            <Link to={"/tasks"} className="foliedge-title-tab">
                                <span className="foliedge-title-tab">
                                    {active_tab === "tasks" ? (
                                        <FontAwesomeIcon icon={faTasks} className="me-2" style={{ color: "#8a160a" }} />
                                    ) : (
                                        <FontAwesomeIcon icon={faTasks} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "tasks" ? (
                                        <FontAwesomeIcon
                                            icon={faTasks}
                                            className="me-2 fa-hover-show"
                                            style={{ color: "#8a160a" }}
                                        />
                                    ) : null}
                                    Tasks
                                    {numOfTasks > 0 ? (
                                        <span className="ms-1">
                                            <small>({numOfTasks > 99 ? "99+" : numOfTasks})</small>
                                        </span>
                                    ) : null}
                                </span>
                            </Link>
                        }
                    >
                        <NewTasksView />
                    </Tab>
                ) : null}
                {modules.includes("Company") ? (
                    <Tab
                        tabClassName={active_tab === "businesses" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="businesses"
                        title={
                            <Link
                                to={
                                    state?.["businesses"]?.activeTab
                                        ? `/businesses/${state["businesses"].activeTab}`
                                        : "/businesses"
                                }
                                className="foliedge-title-tab"
                            >
                                <span className="foliedge-title-tab">
                                    {active_tab === "businesses" ? (
                                        <FontAwesomeIcon
                                            icon={faBriefcase}
                                            className="me-2"
                                            style={{ color: "#917553" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={faBriefcase} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "businesses" ? (
                                        <FontAwesomeIcon
                                            icon={faBriefcase}
                                            className="me-2 fa-hover-show"
                                            style={{ color: "#917553" }}
                                        />
                                    ) : null}
                                    Businesses
                                </span>
                            </Link>
                        }
                    >
                        <CompaniesRoutes />
                    </Tab>
                ) : null}
                {modules.includes("Contact") ? (
                    <Tab
                        tabClassName={active_tab === "contacts" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="contacts"
                        title={
                            <Link
                                to={
                                    state?.["contacts"]?.activeTab
                                        ? `/contacts/${state["contacts"].activeTab}`
                                        : "/contacts"
                                }
                                className="foliedge-title-tab"
                            >
                                <span className="foliedge-title-tab">
                                    {active_tab === "contacts" ? (
                                        <FontAwesomeIcon icon={farAddressBook} className="me-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faAddressBook} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "contacts" && (
                                        <FontAwesomeIcon icon={farAddressBook} className="me-2 fa-hover-show" />
                                    )}
                                    Contacts
                                </span>
                            </Link>
                        }
                    >
                        <ContactsRoutes />
                    </Tab>
                ) : null}
                {modules.includes("Property") ? (
                    <Tab
                        tabClassName={active_tab === "properties" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="properties"
                        title={
                            <Link
                                to={
                                    state?.["properties"]?.activeTab
                                        ? `/properties/${state["properties"].activeTab}`
                                        : "/properties"
                                }
                                className="foliedge-title-tab"
                            >
                                <span className="foliedge-title-tab">
                                    {active_tab === "properties" ? (
                                        <img
                                            src={PropertiesColor}
                                            className="me-2"
                                            alt="Properties Icon"
                                            style={{ maxHeight: "19.19px" }}
                                        />
                                    ) : (
                                        <img
                                            src={PropertiesGray}
                                            className="me-2 fa-hover-hidden"
                                            alt="Properties Icon"
                                            style={{ maxHeight: "19.19px" }}
                                        />
                                    )}
                                    {active_tab !== "properties" ? (
                                        <img
                                            src={PropertiesColor}
                                            className="me-2 fa-hover-show"
                                            alt="Properties Icon"
                                            style={{ maxHeight: "19.19px" }}
                                        />
                                    ) : null}
                                    Properties
                                </span>
                            </Link>
                        }
                    >
                        <PropertiesRoutes />
                    </Tab>
                ) : null}
                {modules.includes("Agreement") ? (
                    <Tab
                        tabClassName={active_tab === "agreements" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="agreements"
                        title={
                            <Link
                                to={
                                    state?.["agreements"]?.activeTab
                                        ? `/agreements/${state["agreements"].activeTab}`
                                        : "/agreements"
                                }
                                className="foliedge-title-tab"
                            >
                                <span className="foliedge-title-tab">
                                    {active_tab === "agreements" ? (
                                        <FontAwesomeIcon icon={farHandshake} className="me-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faHandshake} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "agreements" && (
                                        <FontAwesomeIcon icon={farHandshake} className="me-2 fa-hover-show" />
                                    )}
                                    <div>Agreements</div>
                                </span>
                            </Link>
                        }
                    >
                        <AgreementRoutes />
                    </Tab>
                ) : null}

                {modules.includes("Payment") ? (
                    <Tab
                        tabClassName={active_tab === "payments" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="payments"
                        title={
                            <Link
                                to={
                                    state?.["payments"]?.activeTab
                                        ? `/payments/${state["payments"].activeTab}`
                                        : "/payments"
                                }
                                className="foliedge-title-tab"
                            >
                                <span className="foliedge-title-tab">
                                    {active_tab === "payments" ? (
                                        <FontAwesomeIcon
                                            icon={faMoneyBillWave}
                                            className="me-2"
                                            style={{ color: "green" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={faMoneyBillAlt} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "payments" && (
                                        <FontAwesomeIcon
                                            icon={faMoneyBillWave}
                                            className="me-2 fa-hover-show"
                                            style={{ color: "green" }}
                                        />
                                    )}
                                    Payments
                                </span>
                            </Link>
                        }
                    >
                        <PaymentRoutes />
                    </Tab>
                ) : null}
                {modules.includes("Report") ? (
                    <Tab
                        transition={false}
                        unmountOnExit={true}
                        tabClassName={active_tab === "reports" ? "foliedge-tab" : "foliedge-tab-inactive"}
                        eventKey="reports"
                        title={
                            <Link to={"/reports"} className="foliedge-title-tab">
                                <span className="foliedge-title-tab">
                                    {active_tab === "reports" ? (
                                        <FontAwesomeIcon icon={farClipboard} className="me-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faClipboard} className="me-2 fa-hover-hidden" />
                                    )}
                                    {active_tab !== "reports" ? (
                                        <FontAwesomeIcon icon={farClipboard} className="me-2 fa-hover-show" />
                                    ) : null}
                                    Reports
                                </span>
                            </Link>
                        }
                    >
                        <ReportingRoutes />
                    </Tab>
                ) : null}
            </Tabs>
        </Suspense>
    );
};

export default RoutedTabs;
